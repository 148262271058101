<template>
  <div
    class="bg-white min-h-full w-full flex flex-col overflow-hidden"
  >
    <LayoutHeader :class="['fixed bg-white md:bg-yellow-500']">
      <MenuFullscreen
        v-if="authStore.isAuthenticated || isRouting"
        v-model="isMenuOpen"
      >
        <template #content>
          <div class="flex justify-start items-start flex-col p-5 text-blue-500 gap-3 text-lg font-bold text-center">
            <div
              v-for="e in navEntries"
              :key="e.name"
              class="w-full cursor-pointer"
              @click="navigate(e.name)"
            >
              {{ e.label }}
            </div>
            <div
              class="flex justify-center gap-1 w-full cursor-pointer"
              @click="navigate('notifications')"
            >
              <div>
                {{ t('navigation.notifications') }}
              </div>
              <div class="-translate-y-1 ">
                <div class="rounded-full  bg-blue-500 font-medium text-white h-fit px-1 text-xs">
                  {{ customerNotificationStore.notifications.filter(n => !n.pivot.hasSeen).length }}
                </div>
              </div>
            </div>
            <BaseButton
              class="font-normal m-auto"
              @click="switchLanguage()"
            >
              {{ getAbbreviation() }}
            </BaseButton>
          </div>

          <div class="flex items-center  justify-center text-blue-500 text-lg font-bold mt-5">
            <div
              class="cursor-pointer"
              @click="logout"
            >
              {{ t('navigation.logout') }}
            </div>
          </div>
        </template>
      </MenuFullscreen>
    </LayoutHeader>
    <main :class="['p-5 text-gray-700 w-full flex grow justify-center']">
      <div class="flex flex-col gap-3 font-lato md:items-center transition-all w-full mt-[5rem] sm:w-md">
        <img
          src="/auth-layout-corner.png"
          class="fixed top-0 left-0 h-[14rem] transition-all z-98 invisible md:visible"
          alt="RWS Logo corner"
        >
        <slot><RouterView /></slot>
      </div>
    </main>
    <LayoutFooter />
  </div>
</template>

<script setup lang="ts">
import {setLanguage} from '~/api/axios'
import {i18n, loadLanguageAsync} from '~/modules/i18n'

const isMenuOpen = ref(false)
const {t} = useI18n()
const authStore = useAuthStore()
const customerNotificationStore = useCustomerNotificationStore()
const router = useRouter()
const isRouting = ref(false)

async function navigate(name: string) {
  isRouting.value = true
  if (name === 'order')
    await router.push({name: 'order', query: {day: new Date().toDateString()}})
  else
    await router.push({name})
  isMenuOpen.value = false
  isRouting.value = false
}

async function logout() {
  await authStore.logout()
  await navigate('login')
}

const navEntries = computed(() => {
  const entries = [{
    label: t('navigation.dashboard'),
    name: 'dashboard',
  },
  {
    label: t('navigation.profile'),
    name: 'profile-edit',
  },
  {
    label: t('navigation.diners'),
    name: 'diners',
  },
  {
    label: t('navigation.billing'),
    name: 'profile-billing',
  }]

  if (authStore.user?.customer?.diningParticipants.length !== 0) {
    entries.push({
      label: t('navigation.order'),
      name: 'order',
    })
  }
  entries.push({
    label: t('navigation.service'),
    name: 'customer-service',
  })
  return entries
})

function switchLanguage() {
  if (i18n.global.locale.value === 'de') {
    setLanguage('en')
    loadLanguageAsync('en')
  }
  else {
    loadLanguageAsync('de')
    setLanguage('de')
  }
  return true
}

function getAbbreviation() {
  if (i18n.global.locale.value === 'de')
    return 'EN'
  else return 'DE'
}
</script>

<style lang="scss">
.slide-enter-active,
.slide-leave-active
{
    transition: opacity 150ms ease;
}

.slide-enter-from,
.slide-leave-to {
    opacity: 0;
}

.nav {
  @apply text-blue-500 text-xl
}
</style>
